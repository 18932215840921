import React, {useState, useEffect} from 'react'

import { getNotes, deleteNote } from '../../api/notes';

import { Note, CoupledObject } from '../../types'

import Navigation from './Navigation';
import NoteList from './NoteList';

import { Box } from '@chakra-ui/react';


interface NoteProps {
  toggleViewMode: (newMode: "tasks" | "notes") => void
  setCoupledObjectsState: (newCoupledObjects: CoupledObject[]) => void
}

const Notes = ({toggleViewMode, setCoupledObjectsState}: NoteProps) => {
  const [notes, setNotes] = useState<Note[]>([])

  useEffect(() => {
    const fetch = async () => {
      const notes = await fetchNotes();
      setNotes(notes)
    }
    fetch()
  }, [])

  const fetchNotes = async (filter?: "all" | "today" | "yesterday" | "last_week") => {
    const data = await getNotes(filter)
    const notes = data.map(ele => ({
      id: ele.id,
      title: ele.title,
      body: ele.body,
      timestamp: new Date(ele.timestamp),
    }))
    return notes;
  }

  const handleNoteDelete = async (note: Note) => {
    try {
      await deleteNote (note.id)
      setNotes(notes.filter(ele => ele.id !== note.id))
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleFilterNotes = async (filter?: "all" | "today" | "yesterday" | "last_week") => {
    try {
      const notes = await fetchNotes(filter);
      setNotes(notes);
      setCoupledObjectsState(notes.map(ele => ({type: "note", id: ele.id})) as CoupledObject[])
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <Box>
      <Navigation
        toggleViewMode={toggleViewMode}
        filterNotes={handleFilterNotes}
      />
      <NoteList notes={notes} handleNoteDelete={handleNoteDelete} />
    </Box>
  )
}


export default Notes
