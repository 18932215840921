import React, {useState, useEffect} from 'react'

import { getTasks } from '../../api/tasks';

import { Task, CoupledObject } from '../../types'

import Navigation from './Navigation';
import TaskList from './TaskList';

import { Box } from '@chakra-ui/react';


interface TaskProps {
  toggleViewMode: (newMode: "tasks" | "notes") => void
  setCoupledObjectsState: (newCoupledObjects: CoupledObject[]) => void
}


const Tasks = ({toggleViewMode, setCoupledObjectsState}: TaskProps) => {
  const [tasks, setTasks] = useState<Task[]>([])

  useEffect(() => {
    const fetch = async () => {
      const tasks = await fetchTasks()
      setTasks(tasks)
    }
    fetch()
  }, [])

  const updateSingleTask = (task: Task) => {
    const newTasks = tasks.map(ele => {
      if (ele.id === task.id)
        return task
      else
        return ele
    })
    setTasks(newTasks)
  }

  const fetchTasks = async (filter?:  "all" | "today" | "tomorrow" | "next_week") => {
    const data = await getTasks(filter)
    const tasks = data.map(ele => ({
      id: ele.id,
      title: ele.title,
      body: ele.body,
      state: ele.state,
      frequency: ele.frequency,
      timestamp: new Date(ele.timestamp),
      updated_at: new Date(ele.updated_at),
      due_date: ele.due_date !== null ? new Date(ele.due_date) : null,
      tags: ele.tags,
    }))
    return tasks;
  }

  const handleFilterTasks = async (filter?:  "all" | "today" | "tomorrow" | "next_week") => {
    try {
      const tasks = await fetchTasks(filter);
      setTasks(tasks)
      setCoupledObjectsState(tasks.map(ele => ({type: "task", id: ele.id})) as CoupledObject[]);
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <Box>
      <Navigation
        toggleViewMode={toggleViewMode}
        filterTasks={handleFilterTasks}
      />
      <TaskList
        tasks={tasks}
        updateSingleTask={updateSingleTask}
      />
    </Box>
  )
}


export default Tasks
