import React, { useEffect, useState } from "react";

import { getRawTextInputDetail, performAction } from "../../api/ai";
import { deleteNote } from "../../api/notes";

import { RawInput, RawInputDetail, Note } from "../../types";

import { NoteBox } from "../Notes/NoteList";
import TaskList from "../Tasks/TaskList";

import { Box, Flex, Text, Divider, Button, Stack } from "@chakra-ui/react";
import { CloseIcon } from '@chakra-ui/icons';


interface RawInputDetailDashboardProps {
  toggleViewMode: (newMode: "tasks" | "notes" | "back") => void
  rawInput: RawInput
}


const RawInputDetailDashboard = ({toggleViewMode, rawInput}: RawInputDetailDashboardProps) => {
  const [rawInputDetail, setRawInputDetail] = useState<RawInputDetail | null>(null)

  useEffect(() => {
    const fetch = async () => {
      const data = await getRawTextInputDetail(rawInput.id)
      const rawInputDetail = {
        id: data.id,
        status: data.status,
        text: data.text,
        timestamp: new Date(data.timestamp),
        tasks: data.tasks.map(ele => ({
          id: ele.id,
          title: ele.title,
          body: ele.body,
          state: ele.state,
          frequency: ele.frequency,
          timestamp: new Date(ele.timestamp),
          updated_at: new Date(ele.updated_at),
          due_date: ele.due_date !== null ? new Date(ele.due_date) : null,
          tags: ele.tags,
        })),
        notes: data.notes.map(ele => ({
          id: ele.id,
          title: ele.title,
          body: ele.body,
          timestamp: new Date(ele.timestamp)
        }))
      }
      setRawInputDetail(rawInputDetail)
    }
    fetch()
  }, [rawInput])

  const handlePerformAction = async (action: string) => {
    try {
      const data = await performAction(rawInput.id, action)
      setRawInputDetail({
        id: data.id,
        status: data.status,
        text: data.text,
        timestamp: new Date(data.timestamp),
        tasks: data.tasks.map(ele => ({
          id: ele.id,
          title: ele.title,
          body: ele.body,
          state: ele.state,
          frequency: ele.frequency,
          timestamp: new Date(ele.timestamp),
          updated_at: new Date(ele.updated_at),
          due_date: ele.due_date !== null ? new Date(ele.due_date) : null,
          tags: ele.tags,
        })),
        notes: data.notes.map(ele => ({
          id: ele.id,
          title: ele.title,
          body: ele.body,
          timestamp: new Date(ele.timestamp)
        }))
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  const handleNoteDelete = async (note: Note) => {
    if (rawInputDetail === null) return
    try {
      await deleteNote(note.id)
      const notes = rawInputDetail.notes.filter(ele => ele.id !== note.id)
      setRawInputDetail({...rawInputDetail, notes: notes})
    }
    catch(err){
      console.log("error")
    }
  }

  if (!rawInputDetail) return <></>

  return (
    <Flex
      flexDirection="column"
      h="100%"
      justifyContent="space-between"
    >
      <Flex>
        <Box
          _hover={{
            bg: "whiteAlpha.300",
            transition: "all 0.2s ease-in-out",
          }}
          cursor="pointer"
          p={2}
          borderRadius={8}
          onClick={() => toggleViewMode("back")}
        >
          <CloseIcon color="whiteAlpha.500"/>
        </Box>
        <></>
      </Flex>

      <Box maxH="25vh" overflow="auto">
        {rawInputDetail.text.split("\n").map((text, index) =>
          <Text key={index} color="whiteAlpha.800">{text}</Text>
        )}
      </Box>

      <Divider my={4} color="white" />

      <Box maxH="25vh" overflow="auto">

      <Text fontSize='xl' color="whiteAlpha.900">
        Extracted Notes
      </Text>

      {rawInputDetail && rawInputDetail.notes.map(note => (
        <NoteBox
          key={note.id}
          note={note}
          handleNoteDelete={handleNoteDelete}
        />
      ))}

      </Box>

      <Divider my={4} color="white" />

      <Box maxH="25vh" overflow="auto">
        <Text fontSize='xl' color="whiteAlpha.900">
          Extracted Tasks
        </Text>
        <TaskList
          tasks={rawInputDetail.tasks}
          updateSingleTask={() => {}}
        />
      </Box>

      <Box bottom="2px">
        <Stack spacing={4} direction='row' align='center'>
          <Button
            size="sm"
            color="teal"
            onClick={() => handlePerformAction("clear")}
          >
            Clear
          </Button>
          <Button
            size="sm"
            color="teal"
            onClick={() => handlePerformAction("create-tasks")}
          >
            Extract Tasks
          </Button>
          <Button
            size="sm"
            color="teal"
            onClick={() => handlePerformAction("create-notes")}
          >
            Extract Notes
          </Button>
        </Stack>
      </Box>
    </Flex>
  )
}

export default RawInputDetailDashboard;
