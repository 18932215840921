import React from 'react'

import {
  Divider,
  Center,
  Button,
  Flex,
  Select,
  FormControl,
} from '@chakra-ui/react';


interface NavigationProps {
  toggleViewMode: (newMode: "tasks" | "notes") => void
  filterNotes: (filter?: "all" | "today" | "yesterday" | "last_week") => void
}


const Navigation = ({toggleViewMode, filterNotes}: NavigationProps) => {

  const handleSelectChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const filter = e.target.value as "all" | "today" | "yesterday" | "last_week"
    filterNotes(filter)
  }

  return (
    <Flex align="center">
      <Button colorScheme="teal" mr={4} onClick={() => toggleViewMode("tasks")}>
        Tasks
      </Button>
      <Center height='50px'  mr={4}>
        <Divider
          orientation="vertical"
          color="white"
        />
      </Center>
      <FormControl>
        <Select defaultValue="all" textColor="white" onChange={handleSelectChange}>
          <option value="all" >All</option>
          <option value="today">From Today</option>
          <option value="yesterday">From Yesterday</option>
          <option value="last_week">From Last Week</option>
        </Select>
      </FormControl>
    </Flex>
  )
}


export default Navigation
