import React from 'react'

import { Note } from '../../types'

import {
  VStack,
  Text,
  Card,
  CardBody,
  Flex,
  Button,
} from '@chakra-ui/react';


import {
  DeleteIcon
} from '@chakra-ui/icons';


interface NoteBoxProps {
  note: Note
  handleNoteDelete: (note: Note) => void
}

export const NoteBox = ({note, handleNoteDelete}: NoteBoxProps) => {
  return (
    <Card background="teal.700">
      <CardBody>
        <Flex justifyContent="space-between">
          <Text
            color="white"
            style={{opacity: .8}}
            fontSize="xl"
          >
            {note.title}
          </Text>
          <Button
            size="sm"
            background="transparent"
            _hover={{background: "whiteAlpha.200"}}
            onClick={() => handleNoteDelete(note)}
          >
            <DeleteIcon color="whiteAlpha.400"/>
          </Button>

        </Flex>
        <Text
          color="white"
          style={{opacity: .8}}
        >
          {note.body}
        </Text>
        <Text
          fontSize='xs'
          color="white"
          opacity={.5}
        >
          {note.timestamp.toLocaleString()}
        </Text>
      </CardBody>
    </Card>
  )
}

interface NoteListProps {
  notes: Note[]
  handleNoteDelete: (note: Note) => void
}

const NoteList = ({notes, handleNoteDelete}: NoteListProps) => {

  return (
    <>
      <VStack
        spacing={4}
        align='stretch'
        mt={4}
        overflow={'auto'}
      >
        {notes.map((note, index) =>
          <NoteBox
            note={note}
            key={index}
            handleNoteDelete={handleNoteDelete}
          />)
        }
      </VStack>
    </>
  )
}


export default NoteList
