import React, {useState} from 'react'

import { createRawTextInput, RawTextForm } from '../../api/ai';

import { RawInput } from '../../types'

import {
  Textarea,
  Button,
  Stack,
  FormControl,
} from '@chakra-ui/react';


const initialFormValues = {
  text: "",
}

interface NoteListProps {
  notes: RawInput[]
  addNote: (note: RawInput) => void
}


const NoteForm = ({notes, addNote}: NoteListProps) => {
  const [formValues, setFormValues] = useState<RawTextForm>(initialFormValues)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value
    })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement | HTMLDivElement>) => {
    e.preventDefault();
    try {
      const data = await createRawTextInput(formValues);
      setFormValues(initialFormValues);
      addNote({
        id: data.id,
        status: data.status,
        text: data.text,
        timestamp: new Date(data.timestamp)
      })
    }
    catch (error){
      console.log('error');
    }
  }

  return (
    <Stack
      spacing={6}
      as={'form'}
      onSubmit={handleSubmit}
    >
      <FormControl>
        <Textarea
          placeholder='Type in your notes'
          rows={8}
          focusBorderColor='transparent'
          borderColor='transparent'
          onChange={handleChange}
          value={formValues.text}
          name="text"
        />
      </FormControl>
      <Stack spacing={6}>
        <Button
          colorScheme='teal'
          type="submit"
          size={"sm"}
        >
          Send
        </Button>
      </Stack>
    </Stack>
  )
}


export default NoteForm
