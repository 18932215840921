import axios from './axios'
import { API_BASE_URL } from '../conf'


export interface Task {
  id: number
  title: string
  body: string
  state: "active" | "paused" | "completed"
  frequency: "once" | "daily" | "weekly" | "monthly"
  timestamp: string
  updated_at: string
  tags: string[]
  due_date: string | null
}


export interface TaskPatch {
  state?: "active" | "paused" | "completed"
}


export const getTasks = async (filter?: "all" | "today" | "tomorrow" | "next_week"): Promise<Task[]> => {
  const query = filter ? `?due_date=${filter}` : ''
  const response = await axios.get(`${API_BASE_URL}/tasks/tasks${query}`)
  return response.data as Task[]
}


export const patchTask = async (id: number, data: TaskPatch): Promise<Task> => {
  const response = await axios.patch(`${API_BASE_URL}/tasks/tasks/${id}`, data)
  return response.data as Task
}


export const deleteTask = async (id: number): Promise<void> => {
  await axios.delete(`${API_BASE_URL}/tasks/tasks/${id}`)
}
