import React from 'react'

import { RawInput } from '../../types'

import {
  VStack,
  Text,
  Box,
  Icon,
  Stack,
} from '@chakra-ui/react';

import { CheckIcon, SmallCloseIcon } from '@chakra-ui/icons'


const NoteStateIcon = ({status}: {status: "s" | "f" | "p" | "r"}) => {
  switch (status) {
    case "s":
      return <Icon as={CheckIcon} color="green.500" />
    case "f":
      return <Icon as={SmallCloseIcon} color="red.500" />
    case "p":
      return <Icon as={CheckIcon} color="purple.500" />
    case "r":
      return <Icon as={CheckIcon} color="gray.500" />
  }
}


interface RawInputElementProps {
  rawInput: RawInput
  toggleViewMode: (newMode: "tasks" | "notes" | "raw-input-detail", extraInfo?: Object) => void
}

const RawInputElement = ({rawInput, toggleViewMode}: RawInputElementProps) => {
  return (
    <Box
      _hover={{
        bg: "whiteAlpha.400",
        transition: "all 0.2s ease-in-out",
      }}
      p={2}
      borderRadius={8}
      onClick={() => toggleViewMode("raw-input-detail", rawInput)}
    >
      {rawInput.text.split("\n").map((text, index) => <Text key={index}>{text}</Text>)}
      <Stack direction='row'>
        <NoteStateIcon status={rawInput.status} />
        <Text fontSize='xs' color="blackAlpha.500">
          {rawInput.timestamp.toLocaleString()}
        </Text>
      </Stack>
    </Box>
  )
}

interface RawTextInputListProps {
  rawTextInputs: RawInput[]
  toggleViewMode: (newMode: "tasks" | "notes" | "raw-input-detail", extraInfo?: Object) => void
}

const RawTextInputList = ({rawTextInputs, toggleViewMode}: RawTextInputListProps) => {
  return (
    <VStack
      spacing={4}
      align='stretch'
      mt={4}
      overflow={'auto'}
    >
      {rawTextInputs.map((rawTextInput, index) =>
        <RawInputElement
          rawInput={rawTextInput}
          key={index}
          toggleViewMode={toggleViewMode}
        />
      )}
    </VStack>
  )
}


export default RawTextInputList
