import React, { useState, useEffect } from 'react'

import { patchTask, deleteTask } from '../../api/tasks';

import { Task } from '../../types'

import {
  VStack,
  Text,
  Checkbox,
  Card,
  CardBody,
  Flex,
  Box,
  Button,
} from '@chakra-ui/react';

import {
  DeleteIcon
} from '@chakra-ui/icons';


interface TaskBoxProps {
  task: Task
  handleTaskStateChange: (task: Task) => void
  handleTaskDelete: (task: Task) => void
}

const TaskBox = ({task, handleTaskStateChange, handleTaskDelete}: TaskBoxProps) => {
  return (
    <Card background="teal.700">
      <CardBody>
        <Flex justifyContent="space-between">
          <Box>
            <Checkbox
              size='md'
              colorScheme='teal'
              isChecked={task.state === "completed"}
              onChange={() => handleTaskStateChange(task)}
            >
              <Text
                color="white"
                style={{opacity: .8}}
                as={task.state === "completed" ? "s" : "p"}
              >
                {task.title}
              </Text>
            </Checkbox>
            {
              task.due_date ?
              <Text
                color="white"
                style={{opacity: .5}}
                fontSize="xs"
              >
                Due Date: {task.due_date.toLocaleDateString()}
              </Text> : <></>
            }
          </Box>
          <Button
            size="sm"
            background="transparent"
            _hover={{background: "whiteAlpha.200"}}
            onClick={() => handleTaskDelete(task)}
          >
            <DeleteIcon color="whiteAlpha.400"/>
          </Button>
        </Flex>
      </CardBody>
    </Card>
  )
}

interface TaskListProps {
  tasks: Task[]
  updateSingleTask: (task: Task) => void
}

const TaskList = ({tasks, updateSingleTask}: TaskListProps) => {
  const [toDoTask, setTodoTask] = useState<Task[]>([])
  const [doneTask, setDoneTask] = useState<Task[]>([])

  useEffect(() => {
    const toDoTask = tasks.filter(task => task.state === "active" || task.state === "paused")
    const doneTask = tasks.filter(task => task.state === "completed")
    setTodoTask(toDoTask)
    setDoneTask(doneTask)
  }, [tasks])

  const handleTaskStateChange = async (task: Task) => {
    let ele = null
    if (task.state === "active" || task.state === "paused")
      ele = await patchTask(task.id, {state: "completed"})
    else
      ele = await patchTask(task.id, {state: "active"})
      updateSingleTask({
        id: ele.id,
        title: ele.title,
        body: ele.body,
        state: ele.state,
        frequency: ele.frequency,
        timestamp: new Date(ele.timestamp),
        updated_at: new Date(ele.updated_at),
        due_date: ele.due_date !== null ? new Date(ele.due_date) : null,
        tags: ele.tags,
      })
  }

  const handleTaskDelete = async (task: Task) => {
    try {
      await deleteTask(task.id)
      if (task.state === "completed") setDoneTask(doneTask.filter(ele => ele.id !== task.id))
      else setTodoTask(toDoTask.filter(ele => ele.id !== task.id))
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <VStack
        spacing={4}
        align='stretch'
        mt={4}
        overflow={'auto'}
      >
        {toDoTask.map((task, index) =>
          <TaskBox
            task={task}
            key={index}
            handleTaskStateChange={handleTaskStateChange}
            handleTaskDelete={handleTaskDelete}
          />)
        }
      </VStack>

      <VStack
        spacing={4}
        align='stretch'
        mt={4}
        overflow={'auto'}
      >
        {doneTask.map((task, index) =>
          <TaskBox
            task={task}
            key={index}
            handleTaskStateChange={handleTaskStateChange}
            handleTaskDelete={handleTaskDelete}
          />)
        }
      </VStack>
    </>
  )
}


export default TaskList
