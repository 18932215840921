import axios from 'axios';
import { ENV, API_TOKEN } from '../conf';

import Cookies from 'js-cookie';


const instance = axios.create({});

instance.interceptors.request.use(config => {
  if (ENV === 'local') {
    config.headers['Authorization'] = `Token ${API_TOKEN}`;
  }
  config.withCredentials = true;
  config.headers['X-CSRFToken'] = Cookies.get('csrftoken');
  return config;
});

export default instance;
