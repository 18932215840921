import React, { useEffect, useState } from 'react'

import { getRawTextInput } from '../../api/ai';

import { RawInput, CoupledObject } from '../../types'

import {
  Box,
} from '@chakra-ui/react';

import NoteForm from './NoteForm';
import RawTextInputList from './RawTextInputList';


interface RawInputDashbardProps {
  toggleViewMode: (newMode: "tasks" | "notes" | "raw-input-detail", extraInfo?: Object) => void
  coupledObjects: CoupledObject[]
}

const RawInputDashbard = ({toggleViewMode, coupledObjects}: RawInputDashbardProps) => {
  const [notes, setNotes] = useState<RawInput[]>([])

  useEffect(() => {
    const fetch = async () => {
      const tasks = coupledObjects.filter(ele => ele.type === "task").map(ele => ele.id)
      const notes = coupledObjects.filter(ele => ele.type === "note").map(ele => ele.id)
      const rawTextInputData = await getRawTextInput(tasks, notes)
      const rawTextInput = rawTextInputData.map(ele => ({
        id: ele.id,
        status: ele.status,
        text: ele.text,
        timestamp: new Date(ele.timestamp)
      }))
      setNotes(rawTextInput)
    }
    fetch()
  }, [coupledObjects])

  const addNote = (note: RawInput) => {
    setNotes([note, ...notes])
  }

  return (
    <Box>
      <NoteForm notes={notes} addNote={addNote}/>
      <RawTextInputList rawTextInputs={notes} toggleViewMode={toggleViewMode}/>
    </Box>
  )
}


export default RawInputDashbard
