import axios from './axios'
import { API_BASE_URL } from '../conf'

import { Note } from "./notes"
import { Task } from "./tasks"


export interface RawTextInput {
  id: number
  text: string
  status: "s" | "f" | "p" | "r"
  timestamp: string
}

export interface RawTextDetail {
  id: number
  text: string
  status: "s" | "f" | "p" | "r"
  timestamp: string
  notes: Note[]
  tasks: Task[]
}

export interface RawTextForm {
  text: string
}


export const getRawTextInput = async (tasks?: number[], notes?: number[]): Promise<RawTextInput[]> => {
  let query = ""
  if (tasks !== undefined && tasks.length > 0) query += "tasks=" + tasks.join('&tasks=')
  if (notes !== undefined && notes.length > 0) query += "notes=" + notes.join('&notes=')
  const queryString = query ? `?${query}` : ''
  const response = await axios.get(`${API_BASE_URL}/ai/raw-text-input${queryString}`)
  return response.data as RawTextInput[]
}


export const getRawTextInputDetail = async (id: number): Promise<RawTextDetail> => {
  const response = await axios.get(`${API_BASE_URL}/ai/raw-text-input/${id}`)
  return response.data as RawTextDetail
}


export const performAction = async (id: number, action: string): Promise<RawTextDetail> => {
  const response = await axios.post(`${API_BASE_URL}/ai/raw-text-input/${action}/${id}`)
  return response.data as RawTextDetail
}


export const createRawTextInput = async (data: RawTextForm): Promise<RawTextInput> => {
  const response = await axios.post(`${API_BASE_URL}/ai/raw-text-input`, data)
  return response.data as RawTextInput
}
