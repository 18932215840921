import React, { useState } from 'react';
import "./styles.css"

import {
  Grid,
  GridItem
} from '@chakra-ui/react';

import { CoupledObject } from './types';

import RawInputView from './components/RawInput/index';
import Tasks from './components/Tasks/index';
import Notes from './components/Notes/index';
import RawInputDetail from './components/RawInput/RawInputDetail';

import { RawInput } from './types';

interface ViewMode {
  mode: "tasks" | "notes" | "raw-input-detail" | "back"
  extraInfo?: Object
  lastMode?: "tasks" | "notes" | null
}

interface ContentProps {
  viewMode: ViewMode
  toggleViewMode: (newMode: "tasks" | "notes" | "raw-input-detail" | "back") => void
  setCoupledObjectsState: (newCoupledObjects: CoupledObject[]) => void
}

const Content = ({viewMode, toggleViewMode, setCoupledObjectsState}: ContentProps) => {
  if (viewMode.mode === "notes") return <Notes toggleViewMode={toggleViewMode} setCoupledObjectsState={setCoupledObjectsState}/>
  else if (viewMode.mode === "raw-input-detail" && viewMode.extraInfo) return <RawInputDetail toggleViewMode={toggleViewMode} rawInput={viewMode.extraInfo as RawInput} />
  else return <Tasks toggleViewMode={toggleViewMode} setCoupledObjectsState={setCoupledObjectsState}/>
}


function App() {
  const [viewMode, setViewMode] = useState<ViewMode>({mode: "tasks"})
  const [coupledObjects, setCoupledObjects] = useState<CoupledObject[]>([])

  const toggleViewMode = (
    newMode: "tasks" | "notes" | "raw-input-detail" | "back",
    extraInfo?: Object
  ) => {
    setViewMode({
      mode: newMode === "back" ? viewMode.lastMode as "tasks" | "notes" : newMode,
      extraInfo: extraInfo,
      lastMode: viewMode.mode === "raw-input-detail" || "back" ? null : viewMode.lastMode
    });
    if (newMode === "tasks" || newMode === "notes") setCoupledObjects([]);
  };

  const setCoupledObjectsState = (newCoupledObjects: CoupledObject[]) => {
    setCoupledObjects(newCoupledObjects);
  }

  return (
    <Grid className="grid" templateColumns='repeat(2, 1fr)'>
      <GridItem
        w='100%'
        h='100vh'
        bg='teal.700'
        p="4"
        overflow="auto"
      >
        <Content
          viewMode={viewMode}
          toggleViewMode={toggleViewMode}
          setCoupledObjectsState={setCoupledObjectsState}
        />
      </GridItem>
      <GridItem
        w='100%'
        h='100vh'
        bg='teal.200'
        p="4"
        overflow="auto"
      >
        <RawInputView
          toggleViewMode={toggleViewMode}
          coupledObjects={coupledObjects}
        />
      </GridItem>
    </Grid>
  );
}

export default App;
