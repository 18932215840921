import React from 'react'

import {
  Divider,
  Center,
  Button,
  Flex,
  Select,
  FormControl,
} from '@chakra-ui/react';


interface NavigationProps {
  toggleViewMode: (newMode: "tasks" | "notes") => void
  filterTasks: (filter?:  "all" | "today" | "tomorrow" | "next_week") => void
}

const Navigation = ({toggleViewMode, filterTasks}: NavigationProps) => {

  const handleSelectChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const filter = e.target.value as "all" | "today" | "tomorrow" | "next_week"
    filterTasks(filter)
  }

  return (
    <Flex align="center">
      <Button colorScheme="teal" mr={4} onClick={() => toggleViewMode("notes")}>
        Notes
      </Button>
      <Center height='50px'  mr={4}>
        <Divider
          orientation="vertical"
          color="white"
        />
      </Center>
      <FormControl>
        <Select defaultValue="all" textColor="white" onChange={handleSelectChange}>
          <option value="all">Due Date: All</option>
          <option value="today">Due Date: Today</option>
          <option value="tomorrow">Due Date: Tommorrow</option>
          <option value="next_week">Due Date: Next Week</option>
        </Select>
      </FormControl>
    </Flex>
  )
}


export default Navigation
