import axios from './axios'
import { API_BASE_URL } from '../conf'


export interface Note {
  id: number
  title: string
  body: string
  timestamp: string
}


export const getNotes = async (filter?: "all" | "today" | "yesterday" | "last_week"): Promise<Note[]> => {
  const query = filter ? `?from_date=${filter}` : ''
  const response = await axios.get(`${API_BASE_URL}/notes/notes${query}`)
  return response.data as Note[]
}


export const deleteNote = async (id: number): Promise<void> => {
  await axios.delete(`${API_BASE_URL}/notes/notes/${id}`)
}
